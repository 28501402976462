import React, { Suspense } from "react"
import Layout from "../layouts/layout"
import { graphql } from "gatsby"

const Map = React.lazy(() => import("../components/map/leaflet-map"))

export const query = graphql`
  query {
    red: file(relativePath: { eq: "marker-red.png" }) {
      childImageSharp {
        fluid(maxWidth: 32, maxHeight: 32) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yellow: file(relativePath: { eq: "marker-yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 32, maxHeight: 32) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blue: file(relativePath: { eq: "marker-blue.png" }) {
      childImageSharp {
        fluid(maxWidth: 32, maxHeight: 32) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    green: file(relativePath: { eq: "marker-green.png" }) {
      childImageSharp {
        fluid(maxWidth: 32, maxHeight: 32) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shadow: file(relativePath: { eq: "marker-shadow.png" }) {
      childImageSharp {
        fluid(maxWidth: 32, maxHeight: 32) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Where = ({ data }) => {
  let markers = {
    red: data.red.childImageSharp.fluid.src,
    blue: data.blue.childImageSharp.fluid.src,
    yellow: data.yellow.childImageSharp.fluid.src,
    green: data.green.childImageSharp.fluid.src,
    shadow: data.shadow.childImageSharp.fluid.src,
  }

  let map = null
  if (typeof window !== "undefined" && window.document) {
    map = (
      <Suspense fallback={<p>Loading..</p>}>
        <Map markers={markers} />
      </Suspense>
    )
  }

  return (
    <Layout title="Where">
      <h2 className="text-2xl text-gray-800 font-bold mt-10 md:ml-10">
        Current Settings Covered by SBL COVID-19 Surveillance Screen
      </h2>
      {map}
    </Layout>
  )
}

export default Where
